import router from "next/router"
import Modal from "react-modal"
import Image from "next/legacy/image"
import { useEffect, useState } from "react"
import { useAppContext, usePathData } from "@/lib/contexts/appContext"
import { pushDataLayer } from "@/lib/helpers/tagManager"
import Storage from "@/lib/storage"
import Button from "@/components/common/button/button"
import Close from "@/components/common/icons/close"
import styles from "./popup.module.css"
import classNames from "classnames"

export default function Popup({ data }) {
  const pathData = usePathData()
  const { meta } = useAppContext()
  const [modalIsOpen, setModalIsOpen] = useState(false)

  Modal.setAppElement("html")

  useEffect(() => {
    if (meta?.omitPopup) {
      return
    }

    const popupData = Storage.get("popup", {
      views: 0,
      closed: false,
      timestamp: null
    })

    const currentViews = popupData.views + 1

    if (!popupData.closed) {
      Storage.set("popup", {
        ...popupData,
        views: currentViews
      })
    }

    const currentDate = new Date()
    const seenDate = new Date(popupData?.timestamp)
    const dayInMs = 24 * 60 * 60 * 1000
    const diffInMs = Math.abs(currentDate - seenDate)
    const diffInDays = Math.floor(diffInMs / dayInMs)

    if (
      (data.ttl < diffInDays && popupData?.timestamp) ||
      (!popupData.closed && currentViews > data?.pageViews === true)
    ) {
      setModalIsOpen(true)
      pushDataLayer({
        event: "cta-pop-up-show"
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathData.path])

  if (!data) {
    return null
  }

  const closeModal = (doRedirect = false) => {
    setModalIsOpen(false)

    Storage.set("popup", {
      views: 0,
      closed: true,
      timestamp: Date.now()
    })

    pushDataLayer({
      event: "cta-pop-up-click",
      popupButton: doRedirect
    })

    if (doRedirect) {
      router.push(data.button.url)
    }
  }

  const customStyles = {
    overlay: {
      display: "flex",
      backgroundColor: "rgba(0, 0, 0, .5)",
      zIndex: 1000,
      alignItems: "center",
      justifyContent: "center"
    }
  }

  return (
    <Modal
      isOpen={modalIsOpen}
      style={customStyles}
      shouldReturnFocusAfterClose={true}
      shouldCloseOnOverlayClick={true}
      className={classNames(styles.content, {
        "is-square": data?.image && data?.position === "square",
        "is-landscape": data?.image && data.position === "landscape"
      })}>
      <div className={styles.click} onClick={() => closeModal()}>
        <div className={styles.close}>
          <Close className="icon" />
        </div>
      </div>
      {data?.image && (
        <div className="image-wrapper">
          <figure>
            <Image
              className="object-cover object-center w-full h-full"
              src={data.image.url}
              sizes="(max-width: 768px) 100vw, 50vw"
              alt={data.image.alt}
              layout="fill"
            />
          </figure>
        </div>
      )}
      <div className="content-wrapper">
        <h2>{data.heading}</h2>
        <p>{data.text}</p>
        <Button
          target={data.button.target}
          theme="green"
          className="button"
          ariaLabel={data.button.title}
          onClick={() => closeModal(true)}>
          {data.button.title}
        </Button>
      </div>
    </Modal>
  )
}
